import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path
        d="M12.217 1.994L14.94.134a.338.338 0 01.495.132l1.428 2.973a.338.338 0 00.33.19l3.29-.249a.338.338 0 01.362.362l-.25 3.29a.337.337 0 00.191.33l2.974 1.429c.186.09.249.324.132.494l-1.86 2.725a.338.338 0 000 .38l1.86 2.725a.338.338 0 01-.132.494l-2.974 1.43a.338.338 0 00-.19.329l.249 3.29a.338.338 0 01-.362.362l-3.29-.25a.337.337 0 00-.33.19l-1.428 2.974a.338.338 0 01-.495.133l-2.724-1.86a.338.338 0 00-.381 0l-2.724 1.86a.338.338 0 01-.495-.133l-1.429-2.973a.337.337 0 00-.33-.19l-3.29.249a.338.338 0 01-.361-.362l.25-3.29a.338.338 0 00-.191-.33L.292 15.41a.338.338 0 01-.133-.494L2.02 12.19a.337.337 0 000-.38L.16 9.085a.338.338 0 01.132-.494l2.974-1.43a.338.338 0 00.19-.329l-.25-3.29a.338.338 0 01.363-.362l3.29.25a.338.338 0 00.33-.19L8.616.265a.338.338 0 01.495-.133l2.724 1.86a.337.337 0 00.38 0z"
        fill="#FFC639"
      />
      <path
        d="M12.026 21.113a9.113 9.113 0 100-18.226 9.113 9.113 0 000 18.226z"
        fill="#FFE564"
      />
    </svg>
  )
}

export default SvgComponent

const FOOTERNAVDATA = [
  {
    title: 'Соцсети',
    links: [
      {
        title: 'Facebook',
        link: 'https://www.facebook.com/peremena.media',
      },
      {
        title: 'Telegram',
        link: 'https://t.me/novacitykz',
      },
      {
        title: 'Instagram',
        link: 'https://www.instagram.com/peremena.media/',
      },
    ],
  },
  {
    title: 'Информация',
    links: [
      {
        title: 'О проекте',
        slug: 'about',
      },
      {
        title: 'Консалтинг',
        slug: 'consulting',
      },
      {
        title: 'Авторам',
        slug: 'be-author',
      },
    ],
  },
  {
    title: 'Спецпроекты',
    links: [
      {
        title: 'Toile',
        link: 'https://toile.peremena.media/',
      },
      {
        title: 'Айналайын SOS',
        link: 'https://sos.peremena.media/',
      },
      {
        title: 'Большая Перемена',
        link: 'https://big.peremena.media/',
      }
    ],
  },
]

export default FOOTERNAVDATA

/** @jsx jsx */
import { jsx, Button } from 'theme-ui'
import { Link } from 'gatsby'
import SlidingPane from 'react-sliding-pane'
import 'react-sliding-pane/dist/react-sliding-pane.css'

import Logo from 'src/assets/svgs/logo'
import CloseIcon from 'src/assets/svgs/close'
import FOOTERNAVDATA from 'src/constants/footerNavData'
import useMenuList from 'src/hooks/useMenuList'

const SlidingNav = ({ isPaneOpen, setIsPaneOpen }) => {
  const menuList = useMenuList()

  const footerNavData = [
    {
      title: 'Рубрики',
      links: menuList,
    },
    FOOTERNAVDATA[2],
    FOOTERNAVDATA[1],
  ]

  return (
    <SlidingPane isOpen={isPaneOpen} from="left" width="100vw">
      <div
        sx={{
          variant: 'boxes.container',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: [3, 3, 4],
        }}
      >
        <Link to="/" title="главная страница">
          <Logo width={146} height={45} />
        </Link>
        <Button variant="link" onClick={() => setIsPaneOpen(false)}>
          <CloseIcon width={24} height={24} />
        </Button>
      </div>
      <div
        sx={{
          variant: 'boxes.container',
          py: [3, 3, 4],
        }}
      >
        {footerNavData.map((nav, i) => (
          <nav
            key={i}
            sx={{
              mb: 3,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h3
              sx={{
                color: 'textOnDark',
                fontSize: 3,
                mb: 3,
              }}
            >
              {nav.title}
            </h3>
            <div
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                ml: 2,
              }}
            >
              {nav.links.map((navItem) =>
                navItem.link ? (
                  <a
                    key={navItem.title}
                    href={navItem.link}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                      variant: 'links.textOnDark',
                      mb: 2,
                      fontSize: 0,
                      lineHeight: 2,
                      textTransform: 'uppercase',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {navItem.title}
                  </a>
                ) : (
                  <Link
                    key={navItem.title}
                    to={`/${navItem.slug}`}
                    sx={{
                      variant: 'links.textOnDark',
                      mb: 2,
                      fontSize: 0,
                      lineHeight: 2,
                      textTransform: 'uppercase',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {navItem.title}
                  </Link>
                )
              )}
            </div>
          </nav>
        ))}
      </div>
    </SlidingPane>
  )
}

export default SlidingNav

import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={20} height={20} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.326 12.899l5.38 5.38a1.009 1.009 0 01-1.427 1.426l-5.38-5.38a8 8 0 111.426-1.426zM8 13.999A6 6 0 108 2a6 6 0 000 12z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgComponent

/** @jsx jsx */
import { jsx, Text } from 'theme-ui'
import { Link } from 'gatsby'

import Logo from 'src/assets/svgs/logo'
import RepostIcon from 'src/assets/svgs/repost'
import useMenuList from 'src/hooks/useMenuList'
import FOOTERNAVDATA from 'src/constants/footerNavData'

const Footer = () => {
  const menuList = useMenuList()

  const footerNavData = [
    {
      title: 'Рубрики',
      links: menuList,
    },
    ...FOOTERNAVDATA,
  ]

  return (
    <div
      sx={{
        bg: 'backgroundFooter',
      }}
    >
      <footer
        sx={{
          variant: 'boxes.container',
          width: '100%',
          maxWidth: '1100px',
          display: 'flex',
          flexDirection: ['column', 'row'],
          justifyContent: [null, 'space-between'],
          mx: 'auto',
          py: [5, 9],
        }}
      >
        <div sx={{ mb: [5, 0] }}>
          {footerNavData.map((nav, i) => (
            <nav
              key={i}
              sx={{
                mb: [5, 3],
                display: 'flex',
                flexDirection: ['column', 'row'],
              }}
            >
              <Text
                as="h3"
                sx={{
                  color: 'textOnDark',
                  fontSize: 2,
                  mt: [0, '2px'],
                  mr: [null, 4],
                  mb: [2, 0],
                }}
              >
                {nav.title}
              </Text>
              <div
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flex: '1',
                }}
              >
                {nav.links.map((navItem) =>
                  navItem.link ? (
                    <a
                      key={navItem.title}
                      href={navItem.link}
                      target="_blank"
                      rel="noreferrer"
                      sx={{
                        variant: 'links.textOnDark',
                        mr: 3,
                        fontSize: 0,
                        lineHeight: 2,
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {navItem.title}
                    </a>
                  ) : (
                    <Link
                      key={navItem.title}
                      to={`/${navItem.slug}`}
                      sx={{
                        variant: 'links.textOnDark',
                        mr: 3,
                        fontSize: 0,
                        lineHeight: 2,
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {navItem.title}
                    </Link>
                  )
                )}
              </div>
            </nav>
          ))}
        </div>

        <div sx={{ maxWidth: 221 }}>
          <Link to="/" title="Домашняя страница">
            <Logo width={221} height={67} sx={{ mb: 2 }} />
          </Link>
          <Text as="p" sx={{ mb: 2, color: 'textOnDark', fontSize: 0 }}>
            © 2020 Перемена.медиа
          </Text>
          <div sx={{ display: 'flex' }}>
            <RepostIcon width={23} height={23} sx={{ mr: 2 }} />
            <Text
              as="p"
              sx={{
                width: 'calc(100% - 32px)',
                color: 'textPale',
                fontSize: '10px',
              }}
            >
              Копирование материалов разрешено только при наличии активной
              ссылки на Перемена.медиа
            </Text>
          </div>
        </div>
      </footer>
      <div sx={{ borderTop: '1px solid', borderColor: 'topPostsBorder' }}>
        <div
          sx={{
            variant: 'boxes.container',
            width: '100%',
            maxWidth: '1100px',
            mx: 'auto',
            py: 3,
          }}
        >
          <div
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <p
              sx={{
                mr: 3,
                mb: 0,
                color: 'textOnDark',
                fontSize: 1,
                fontWeight: 'bold',
                fontFamily: 'heading',
              }}
            >
              Developed by:{' '}
              <a
                sx={{ color: 'textOnDark', fontSize: 1, fontFamily: 'heading' }}
                href="https://tbntdima.com/"
                target="_blank"
                rel="noreferrer"
              >
                Dima An
              </a>{' '}
            </p>
            <p
              sx={{
                mb: 0,
                color: 'textOnDark',
                fontSize: 1,
                fontWeight: 'bold',
                fontFamily: 'heading',
              }}
            >
              Designed by:{' '}
              <a
                sx={{ color: 'textOnDark', fontSize: 1, fontFamily: 'heading' }}
                href="https://amaltapalov.com/"
                target="_blank"
                rel="noreferrer"
              >
                Amal Tapalov
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer

import * as React from 'react'
import uniqueId from 'lodash/uniqueId'

function SvgComponent(props) {
  const a = uniqueId()
  const b = uniqueId()
  const c = uniqueId()
  const d = uniqueId()
  return (
    <svg width={146} height={45} viewBox="0 0 146 45" fill="none" {...props}>
      <path
        d="M11.846 42.848H5.414a1.872 1.872 0 01-1.867-1.867V24.439c0-1.027.84-1.867 1.867-1.867h6.432v20.276z"
        fill={`url(#prefix__paint${a}_linear)`}
      />
      <path
        d="M26.372 9.746L24.7 6.86a.472.472 0 00-.813 0l-1.67 2.885-2.073 3.584v29.518h8.299V13.33l-2.072-3.584z"
        fill={`url(#prefix__paint${b}_linear)`}
      />
      <path
        d="M34.885 42.848h-6.432V4.668c0-1.027.84-1.867 1.867-1.867h4.565c1.027 0 1.867.84 1.867 1.867V40.98c0 1.027-.84 1.867-1.867 1.867z"
        fill={`url(#prefix__paint${c}_linear)`}
      />
      <path
        d="M20.145 42.848h-8.299v-27.65c0-1.027.84-1.868 1.867-1.868h6.432v29.518z"
        fill={`url(#prefix__paint${d}_linear)`}
      />
      <path
        d="M54.265 23.329h-3.548V10.363h-3.594v12.966h-3.547V7.273h10.689v16.056zM66.176 20.229v3.1h-9.27V7.271h9.018v3.09h-5.47v3.323h4.966v2.987h-4.966v3.557h5.722zM78.993 12.733c0 3.417-2.147 5.442-5.769 5.442h-1.522v5.163h-3.547V7.272h5.069c3.622 0 5.77 2.016 5.77 5.461zm-3.65 0c0-1.559-.747-2.362-2.175-2.362h-1.475v4.705h1.475c1.428-.01 2.175-.812 2.175-2.343zM89.756 20.229v3.1h-9.27V7.271h9.018v3.09h-5.47v3.323H89v2.987h-4.966v3.557h5.722zM106.765 7.272v16.066h-3.473v-3.95c.103-1.95.178-3.901.271-5.852l-.121-.047-1.578 4.22-.878 2.184h-3.5l-.878-2.184-1.577-4.22-.122.047c.075 1.95.178 3.902.252 5.853v3.949h-3.444V7.272h3.575l2.745 6.077c.345.803.952 2.203 1.148 2.8h.102c.197-.597.803-1.997 1.149-2.8l2.744-6.077h3.585zM118.686 20.229v3.1h-9.27V7.271h9.018v3.09h-5.471v3.323h4.967v2.987h-4.967v3.557h5.723zM131.4 7.272v16.066h-3.575v-6.544h-3.594v6.544h-3.576V7.272h3.576v6.422h3.594V7.272h3.575zM140.511 20.668h-3.697l-.578 2.66h-3.669l4.201-16.065h3.799l4.201 16.065h-3.669l-.588-2.66zm-.7-3.1l-.402-1.839c-.224-1.148-.522-3.099-.7-4.406h-.102c-.178 1.307-.448 3.258-.7 4.406l-.402 1.84h2.306zM44.5 38.124h-.961v-11.66h.429l5.125 7.544 5.078-7.543h.439v11.66h-.962v-9.28l-4.452 6.59h-.234l-4.462-6.58v9.27zM64.59 38.124H57.54v-11.66h7.02v.916h-6.058v4.387h5.647v.896h-5.647v4.547h6.086v.914zM71.086 26.465l3.977 10.763h1.074v3.267h-.99v-2.361h-8.644v2.361h-.99v-3.267h1.074l4.005-10.763h.494zm-.27 1.727l-3.202 9.036h6.403l-3.201-9.036zM78.424 38.124h-.532v-11.66h.961v9.7l7.45-9.7h.55v11.66h-.97v-9.746l-7.46 9.746zM98.205 38.124H97.15l-1.391-3.538h-4.91l-1.391 3.538h-1.055l4.658-11.66h.523l4.62 11.66zM95.44 33.69l-2.128-5.507-2.147 5.507h4.275z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id={`prefix__paint${a}_linear`}
          x1={15.21}
          y1={36.652}
          x2={0.669}
          y2={28.257}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0264FF" />
          <stop offset={1} stopColor="#0DC1FD" />
        </linearGradient>
        <linearGradient
          id={`prefix__paint${b}_linear`}
          x1={34.745}
          y1={31.933}
          x2={15.74}
          y2={20.96}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBAC01" />
          <stop offset={1} stopColor="#FFF000" />
        </linearGradient>
        <linearGradient
          id={`prefix__paint${c}_linear`}
          x1={43.621}
          y1={29.581}
          x2={21.242}
          y2={16.66}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BA032E" />
          <stop offset={1} stopColor="#FF0036" />
        </linearGradient>
        <linearGradient
          id={`prefix__paint${d}_linear`}
          x1={25.498}
          y1={33.576}
          x2={7.086}
          y2={22.945}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#077937" />
          <stop offset={1} stopColor="#06F766" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgComponent

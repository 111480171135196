/** @jsx jsx */
import { Fragment } from 'react'
import { useState, useEffect, useRef } from 'react'
import { jsx, useThemeUI, Button } from 'theme-ui'
import { Link, navigate } from 'gatsby'
import ReactTooltip from 'react-tooltip'
import { useLocation } from '@reach/router'

import Logo from 'src/assets/svgs/logo'
import HamburgerIcon from 'src/assets/svgs/hamburger'
import MoonIcon from 'src/assets/svgs/moon'
import SunIcon from 'src/assets/svgs/sun'
import SeachIcon from 'src/assets/svgs/search'
import ShareIcon from 'src/assets/svgs/share'
import SlidingMenu from 'src/components/slidingMenu'
import useMenuList from 'src/hooks/useMenuList'
import ShareButtons from 'src/components/shareButtons'

const Header = () => {
  const shareTooltipRef = useRef(null)
  const [isPaneOpen, setIsPaneOpen] = useState(false)
  const { colorMode, setColorMode } = useThemeUI()
  const menuList = useMenuList()
  const { href: pageUrl } = useLocation()

  useEffect(() => {
    // prevent scolling on mobile devices
    document.querySelector('body').style.overflow = isPaneOpen
      ? 'hidden'
      : 'visible'
  }, [isPaneOpen])

  const isLightMode = colorMode === 'light'

  return (
    <Fragment>
      <div
        sx={{
          display: ['none', 'flex'],
        }}
      >
        <div
          sx={{
            width: '50%',
            bg: '#3491FF',
            textAlign: 'center',
            py: 1,
            fontWeight: 'bold',
          }}
        >
          <a
            href="https://toile.peremena.media/"
            target="_blank"
            rel="noreferrer"
            sx={{
              color: '#F3E12C',
            }}
          >
            СПЕЦПРОЕКТ: Toile
          </a>
        </div>
        <div
          sx={{
            width: '50%',
            bg: '#E20613',
            textAlign: 'center',
            py: 1,
            fontWeight: 'bold',
          }}
        >
          <a
            href="https://sos.peremena.media/"
            target="_blank"
            rel="noreferrer"
            sx={{
              color: '#ffffff',
            }}
          >
            СПЕЦПРОЕКТ: Айналайын SOS
          </a>
        </div>
      </div>

      <div
        sx={{
          bg: 'backgroundHeader',
        }}
      >
        <header
          sx={{
            variant: 'boxes.container',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: 3,
            px: [4, 4, 9],
          }}
        >
          <Link to="/" title="главная страница" sx={{ display: 'flex' }}>
            <Logo width={146} height={45} />
          </Link>

          <nav
            sx={{
              flex: 1,
              display: ['none', 'none', 'flex'],
              justifyContent: 'space-between',
              maxWidth: 577,
              mx: 4,
            }}
          >
            {menuList.map((menuItem) => (
              <Link
                key={menuItem.title}
                to={`/${menuItem.slug}`}
                sx={{
                  variant: 'links.textOnDark',
                  fontSize: 0,
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
              >
                {menuItem.title}
              </Link>
            ))}
          </nav>

          <div sx={{ display: 'flex' }}>
            <Button
              title="поиск"
              onClick={() => navigate('/search')}
              variant="link"
              sx={{ display: 'flex', ml: 3, p: 1 }}
            >
              <SeachIcon width={20} height={20} />
            </Button>
            <Button
              title="поделиться"
              onMouseEnter={() => {}}
              onClick={() => ReactTooltip.show(shareTooltipRef.current)}
              onFocus={() => ReactTooltip.show(shareTooltipRef.current)}
              onBlur={() => ReactTooltip.hide()}
              variant="link"
              sx={{ display: ['none', 'none', 'flex'], ml: 3, p: 1 }}
            >
              <span ref={shareTooltipRef} data-tip />
              <ShareIcon width={20} height={20} />
            </Button>
            <Button
              title={
                isLightMode ? 'включить темную тему' : 'включить светлую тему'
              }
              onClick={() => setColorMode(isLightMode ? 'dark' : 'light')}
              variant="link"
              sx={{ ml: 3, p: 1 }}
            >
              {isLightMode ? (
                <MoonIcon width={24} height={24} />
              ) : (
                <SunIcon width={24} height={24} />
              )}
            </Button>
            <Button
              title="открыть меню"
              onClick={() => setIsPaneOpen(true)}
              variant="link"
              sx={{
                display: [null, null, 'none'],
                ml: 3,
                p: 1,
              }}
            >
              <HamburgerIcon width={24} height={20} />
            </Button>
          </div>
        </header>

        <SlidingMenu isPaneOpen={isPaneOpen} setIsPaneOpen={setIsPaneOpen} />
      </div>

      <ReactTooltip place="bottom" clickable uuid="shareButtonsTooltip">
        <ShareButtons url={pageUrl} isSmall />
      </ReactTooltip>
    </Fragment>
  )
}

export default Header

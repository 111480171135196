import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path
        d="M14.348 12l7.16-7.144a1.672 1.672 0 00-2.365-2.364L12 9.652l-7.143-7.16a1.672 1.672 0 00-2.364 2.364L9.653 12l-7.16 7.144a1.665 1.665 0 000 2.364 1.667 1.667 0 002.364 0L12 14.347l7.143 7.16a1.667 1.667 0 002.365 0 1.666 1.666 0 000-2.364l-7.16-7.144z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgComponent

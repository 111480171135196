import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path
        d="M16.163.75c1.2 1.8 1.912 3.975 1.912 6.3.038 6.263-5.1 11.325-11.438 11.325-2.137 0-4.162-.6-5.887-1.613 1.95 3.863 5.963 6.488 10.613 6.488 6.562 0 11.887-5.25 11.887-11.738 0-4.8-2.925-8.925-7.087-10.762z"
        fill="#FFCE31"
      />
    </svg>
  )
}

export default SvgComponent

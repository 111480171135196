/** @jsx jsx */
import { jsx } from 'theme-ui'
import Modal from 'react-modal'

import Header from './header'
import Footer from './footer'

Modal.setAppElement('#___gatsby')

const Layout = ({ children }) => (
  <div
    sx={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Header />
    <div sx={{ flexGrow: 1, display: 'flex', bg: 'background' }}>
      <main sx={{ width: '100%' }}>{children}</main>
    </div>
    <Footer />
  </div>
)

export default Layout

import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={24} height={20} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.667C0 1.225.18.8.502.488A1.74 1.74 0 011.714 0h20.572c.454 0 .89.176 1.212.488.321.313.502.737.502 1.179 0 .442-.18.866-.502 1.178a1.74 1.74 0 01-1.212.488H1.714a1.74 1.74 0 01-1.212-.488A1.644 1.644 0 010 1.667zM0 10c0-.442.18-.866.502-1.179a1.74 1.74 0 011.212-.488h20.572c.454 0 .89.176 1.212.488.321.313.502.737.502 1.179 0 .442-.18.866-.502 1.178a1.74 1.74 0 01-1.212.489H1.714a1.74 1.74 0 01-1.212-.489A1.644 1.644 0 010 10zm10.286 8.333c0-.442.18-.866.502-1.178A1.74 1.74 0 0112 16.667h10.286c.454 0 .89.175 1.212.488.321.312.502.736.502 1.178 0 .442-.18.866-.502 1.179a1.74 1.74 0 01-1.212.488H12a1.74 1.74 0 01-1.212-.488 1.643 1.643 0 01-.502-1.179z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgComponent

import { useStaticQuery, graphql } from 'gatsby'

const useMenuList = () => {
  const data = useStaticQuery(graphql`
    query myQuery {
      allWordpressCategory {
        nodes {
          name
          slug
        }
      }
    }
  `)

  const notWantedLinks = ['Видео', 'Блог', 'Категория', 'ТОП!', 'Тесты']
  const menuList = data.allWordpressCategory.nodes
    .filter((category) => !notWantedLinks.includes(category.name))
    .map((category) => ({
      title: category.name,
      slug: `category/${category.slug}`,
    }))

  return menuList
}

export default useMenuList

import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={23} height={23} fill="none" {...props}>
      <path
        d="M13.901 5.762a.472.472 0 00-.266.08l-5.431 3.55H5.69a.246.246 0 00-.245.246v4.412c0 .135.11.245.245.245h2.513l5.43 3.55a.489.489 0 00.76-.411V6.254a.491.491 0 00-.493-.492zm-.611 10.54l-4.483-2.93-.275-.18H6.549v-2.697h1.983l.274-.179 4.484-2.93v8.916zm5.331-5.01h-1.96a.246.246 0 00-.246.245v.613c0 .135.11.245.245.245h1.961c.135 0 .245-.11.245-.245v-.613a.246.246 0 00-.245-.245zm-.642 4.01l-1.69-.975a.244.244 0 00-.332.09l-.305.529a.245.245 0 00.09.334l1.689.976a.242.242 0 00.332-.09l.305-.53a.246.246 0 00-.089-.333zM15.956 9.27a.244.244 0 00.332.09l1.69-.976a.245.245 0 00.089-.334l-.304-.527a.244.244 0 00-.332-.09l-1.69.976a.245.245 0 00-.089.334l.304.527z"
        fill="#BDD9D7"
      />
      <path stroke="#BDD9D7" d="M.5.5h22v22H.5z" />
    </svg>
  )
}

export default SvgComponent
